import React from "react";

import Ready from "../ready/ready";
import Exam from "../exam/exam";
import Latest from "../latest/latest";
import UseCaseTopBanner from "../top_banner/product_top_banner";
import Charts from "../charts/charts";
import Logos from "../logos-section/logos";
import { useTranslation } from 'react-i18next';

const Certification = () => {
  const {t} = useTranslation();
  const exams = [
    {
      title: `${t('a_secure_system')}`,
      description:
        `${t('expand_your_teachings')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/graph_board.svg",
    },
    {
      title: `${t('a_hyper_flexible_solution')}`,
      description:
        `${t('offer_your_learners_and_yourself')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/infinity_with_peoples_inside.svg",
    },
    {
      title: `${t('lms_integration')}`,
      description:
        `${t('our_secure_system_integrates')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/people_connecting_switch.svg",
    },
    {
      title: `${t('5_star_student_experience')}`,
      description:
        `${t('identify_your_learners_strengths')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/five_stars_screen_with_people.svg",
    },
  ];
  const LatestData = {
    title1: "Découvrez notre ",
    title2: "dernière publication",
    description:
      `${t('how_to_create_and_deploy_your_online')}`,
    btnText: `${t('learn_more')}`,
  };

  const ChartData={title:["HCFDC utilise Mereos"," pour ses certifications en gestion de crise"],contexte:`${t('hcfdc_is_a_recognized_association')}`,Résultats:[{heading:" 80%",desc:`${t('reduction_in_cost_per_session_on_average')}`},{heading:"50%",desc:`${t('time_saving_for_certifiers')}`},{heading:"4,9",desc:`${t('average_score_given_by_candidates')}`}],points:[`${t('monitoring')}`,`${t('reactivity')}`,`${t('flexibility')}`,"Made in France"]}


  // const breakpoints = useBreakpoint();
  return (
    <div className={`conatiner font-sans`}>
      <UseCaseTopBanner
        pageTitle="Certification"
        title={t('assess_your_online_learners')}
        description={t('no_longer_require_your_learners')}
        imageSrc="https://d2lxkizvrhu4im.cloudfront.net/images/certification.svg"
        rightArrowImageSrc="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_right_white_icon.svg"
        leftButton={{
          text: `${t("free_trials")}`,
          href: "/free-trial",
        }}
        rightButton={{
          text: `Demo >`,
          href: "/demo",
        }}
        bannerType="useCase"
      />

      {/* logos  */}
      <Logos />
      <div className={`mx-auto w-11/12 lg:w-5/6 `}>
        <div className="my-20">
          <Exam
            Data={exams}
            title={t('why_is_mereos_right_for_you')}
            page={"certification"}
          />
        </div>
      </div>

      <div className={`mx-auto w-11/12 lg:w-5/6`}>
        {/* Latest from Source */}
        <Latest data={LatestData} />

        {/* Charts */}
        <Charts ChartData={ChartData}/>
      </div>

      {/* Ready */}
      <div className="flex flex-row mt-10 mb-20">
        <img
          alt="right star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          className="hidden md:block self-end"
        />
        <div className="mt-10 mx-auto w-11/12 lg:w-5/6">
          <Ready />
        </div>

        <img
          alt="left star"
          src="https://d2lxkizvrhu4im.cloudfront.net/icons/left_star_icon.svg"
          className="hidden md:block self-start"
        />
      </div>
    </div>
  );
};

export default Certification;
