import * as React from "react";

import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import CertificationComponent from "../components/use_case/Certification";
import { useTranslation } from 'react-i18next';

const Certification = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title={t('online_certification')}
        description={t('a_reliable_and_effective_method')}
      />
      <Layout>
        <CertificationComponent />
      </Layout>
    </>
  );
};

export default Certification;
